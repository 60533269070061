const palette = {
    grey: {
        0: '#FFFFFF',
        950: '#1C1C1E',
        1000: '#000000',
    },
    red: {
        800: '#A10010',
    },
    yellow: {
        300: '#FED709',
    },
};

const theme = {
    background: {
        primary: palette.grey[950],
    },
    label: {
        primary: palette.grey[0],
        quaternary: palette.grey[1000],
    },
    warning: {
        default: palette.yellow[300],
    },
    danger: {
        background: palette.red[800],
    },
};

export default theme;
