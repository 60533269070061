import styled from 'styled-components';

export const DefaultTag = styled.div`
    padding: 8px;
    background-color: ${(props) => props.theme.warning.default};
    color: ${(props) => props.theme.label.quaternary};
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    flex-wrap: wrap;
    align-items: center;
`;

export const RedTag = styled(DefaultTag)`
    background-color: ${(props) => props.theme.danger.background};
    color: ${(props) => props.theme.label.primary};
`;
