import styled from 'styled-components';
import { FC } from 'react';
import { DetectionImage } from '../Popup';

const Wrapper = styled.div`
    display: flex;
    max-width: 40%;
`;

const ChapterDescription = styled.div`
    padding: 2rem;
    background-color: rgba(0, 161, 255, 0.7);
    border: 0.5px solid rgba(255, 255, 255, 0.7);
`;

interface Props {
    title?: string;
    description: string[];
    info?: string[];
    image?: string;
}
const BasicStoryTemplate: FC<Props> = ({ title, description, image }) => {
    return (
        <Wrapper>
            {(title || image || (description && description.length > 0)) && (
                <ChapterDescription>
                    {title && <h2>{title}</h2>}
                    {description && description.map((d, i) => <p key={i}>{d}</p>)}
                    {image && <DetectionImage image_path={image} alt={'chapter illustration'} />}
                </ChapterDescription>
            )}
        </Wrapper>
    );
};

export default BasicStoryTemplate;
